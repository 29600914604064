import DateRangePicker from 'daterangepicker/daterangepicker.js'
import moment from 'moment'

export default class DatePickers {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (document.body.matches('.transactions.index')) {
        this.transactionSearch()
      }
    })
  }

  transactionSearch() {
    // DateRangePicker https://www.daterangepicker.com/#options
    $('#transaction_search input[name="daterange"]').daterangepicker({
      'opens': 'center',
      'alwaysShowCalendars': true,
      'autoApply': true,
      'ranges': {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
    }, function (start, end) {
      $('#q_transaction_date_gteq').val(start.format("YYYY-MM-DD"))
      $('#q_transaction_date_lteq').val(end.format("YYYY-MM-DD"))
    })
  }
}
