export default class TransactionForm {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (document.body.matches('.transactions.new, .transactions.edit')) {
        this.initializeTransactionForm()
        this.bindEventListeners()
      }
    })
  }

  initializeTransactionForm() {
    this.checkNumber = document.getElementById('check-number')
    this.interestRate = document.getElementById('interest-rate')
    this.paymentMethod = document.getElementById('transaction_payment_method_id')
    this.transactionDescription = document.getElementById('transaction_description')
    this.transactionPurpose = document.getElementById('transaction_transaction_purpose_id')
    this.transactionSubType = document.getElementById('transaction_transaction_sub_type_id')
  }

  bindEventListeners() {
    this.paymentMethod.addEventListener('change', this.handlePaymentMethodChange.bind(this))
    this.transactionPurpose.addEventListener('change', this.handleTransactionPurposeChange.bind(this))
    this.transactionSubType.addEventListener('change', this.handleTransactionSubTypeChange.bind(this))
  }

  handlePaymentMethodChange(event) {
    const selected = event.target.options[event.target.selectedIndex].text
    const check = new RegExp('check', 'i')

    if (check.test(selected)) {
      this.enableCheckNumber()
    } else {
      this.disableCheckNumber()
    }
  }

  handleTransactionPurposeChange(event) {
    const selected = event.target.options[event.target.selectedIndex].text
    const need_description = new RegExp('need description', 'i')

    if (need_description.test(selected)) {
      this.requireDescription()
    } else {
      this.optionalDescription()
    }
  }

  handleTransactionSubTypeChange(event) {
    const selected = event.target.options[event.target.selectedIndex].text
    const loan_received = new RegExp('loan received', 'i')
    
    if (loan_received.test(selected)) {
      this.enableInterestRate()
    } else {
      this.disableInterestRate()
    }
    
    const requires_payment_method = new RegExp("cash contribution|loan received \.non-exempt\.", 'i')
    if (requires_payment_method.test(selected)) {
      this.paymentMethod.required = true
    } else {
      this.paymentMethod.required = false
    }
  }

  enableCheckNumber() {
    this.checkNumber.classList.remove('d-none')
    this.checkNumber.querySelectorAll('input').forEach(input => input.disabled = false)
  }

  disableCheckNumber() {
    this.checkNumber.classList.add('d-none')
    this.checkNumber.querySelectorAll('input').forEach(input => input.disabled = true)
  }

  enableInterestRate() {
    this.interestRate.classList.remove('d-none')
    this.interestRate.querySelectorAll('input').forEach(input => {
      input.disabled = false
      input.required = true
    })
  }

  disableInterestRate() {
    this.interestRate.classList.add('d-none')
    this.interestRate.querySelectorAll('input').forEach(input => {
      input.disabled = true
      input.required = false
    })
  }

  requireDescription() {
    this.transactionDescription.required = true
  }

  optionalDescription() {
    this.transactionDescription.required = false
  }
}