export default class ClickableRowHandler {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('table tr[data-href]').forEach((row) => {
        row.addEventListener('click', (e) => {
          window.location = e.target.parentElement.dataset.href;
        })
      })
    })
  }
}
