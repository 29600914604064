export default class ContactForm {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (document.body.matches('.contacts.new, .contacts.edit')) {
        this.initializeContactForm()
        this.bindEventListeners()
      }
    })
  }

  initializeContactForm() {
    this.employmentStatus = document.getElementById('contact_employment_status')
    this.employment_inputs = [
      document.getElementById('contact_occupation'),
      document.getElementById('contact_employer_name'),
      document.getElementById('contact_employer_city'),
      document.getElementById('contact_employer_state')
    ]

    this.contactType = document.getElementById('contact_contact_type_id')
  }

  bindEventListeners() {
    this.employmentStatus.addEventListener('change', this.handleEmploymentStatusChange.bind(this))
    this.contactType.addEventListener('change', this.handleContactTypeChange.bind(this))
  }

  handleEmploymentStatusChange(event) {
    if (event.target.value === 'employed') {
      this.employment_inputs.forEach(input => input.required = true)
    } else {
      this.employment_inputs.forEach(input => input.required = false)
    }
  }

  handleContactTypeChange(event) {
    const selected = event.target.options[event.target.selectedIndex].text
    const person = new RegExp('individual|family', 'i')

    if (person.test(selected)) {
      this.disableSingleName()
      this.enableFullName()
      this.enableEmployment()
    } else {
      this.enableSingleName()
      this.disableFullName()
      this.disableEmployment()
    }
  }

  enableSingleName() {
    document.getElementById('single-name').classList.remove('d-none')
    document.getElementById('single-name').querySelectorAll('input').forEach(input => input.disabled = false)
  }

  disableSingleName() {
    document.getElementById('single-name').classList.add('d-none')
    document.getElementById('single-name').querySelectorAll('input').forEach(input => input.disabled = true)
  }

  enableFullName() {
    document.getElementById('full-name').classList.remove('d-none')
    document.getElementById('full-name').querySelectorAll('input').forEach(input => input.disabled = false)
  }

  disableFullName() {
    document.getElementById('full-name').classList.add('d-none')
    document.getElementById('full-name').querySelectorAll('input').forEach(input => input.disabled = true)
  }

  enableEmployment() {
    document.getElementById('employment').classList.remove('d-none')
    document.getElementById('col2').classList.remove('d-none')
    document.getElementById('employment').querySelectorAll('input, select').forEach(input => input.disabled = false)
  }

  disableEmployment() {
    document.getElementById('employment').classList.add('d-none')
    document.getElementById('col2').classList.add('d-none')
    document.getElementById('employment').querySelectorAll('input, select').forEach(input => input.disabled = true)
  }
}
